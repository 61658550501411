.clickable:hover {
    cursor: pointer;
    background: #f5faff;
    color: #606060 !important;
}

.clickable:hover .text-muted {
    color: #727272 !important;
}

.clickable span:hover {
    text-decoration: underline;
}

.activityBar {
    height: 100vh;
    background: rgb(255, 253, 253);
    right: 0px;
    top: 0px;
    position: relative;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    width: 100%;
    overflow: auto;
}

.content {
    z-index: 99;
}

.navbar {
    position: sticky;
    top: 0px;
    z-index: 100 !important;
    background: #f5f8fb;
    border-bottom: solid 1px #eeeeee;
}

.mainNavbar {
    /* height:95vh; */
    overflow: auto;
    border-right: 1px solid #eeeeee
}

.sidebar-inner .accordion-button:not(.collapsed),
.sidebar-inner .accordion-button:not(.collapsed) .sidebar-icon {
    color: #fff !important
}

@media (min-width: 768px) {
    .content {
        /* margin-left: 320px; */
        margin-left: 260px;
    }

}

@media (max-width: 575.98px) {
    .sidebar {
        width: 100%;
        margin-left: 0px !important;
    }
}

/*
*
* ==========================================
* CUSTOM UTIL CLASSES
* ==========================================
*
*/

/* Timeline holder */

ul.timeline {
    list-style-type: none;
    position: relative;
    padding-left: 1.5rem;
}

/* Timeline vertical line */

ul.timeline:before {
    content: ' ';
    background: #fff;
    display: inline-block;
    position: absolute;
    left: 16px;
    width: 4px;
    height: 100%;
    z-index: 400;
    border-radius: 1rem;
}

li.timeline-item {
    margin: 20px 30px;
}

/* Timeline item arrow */

.timeline-arrow {
    border-top: 0.5rem solid transparent;
    border-right: 0.5rem solid #fff;
    border-bottom: 0.5rem solid transparent;
    display: block;
    position: absolute;
    left: 2rem;
}

/* Timeline item circle marker */

li.timeline-item::before {
    content: ' ';
    background: #ddd;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #fff;
    left: 11px;
    width: 14px;
    height: 14px;
    z-index: 400;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.invalid {
    color: red;
}

.calendarStyle {
    z-index: 1;
}

.calendarStyle .fc-daygrid-day-frame {
    max-height: 35px;
}

.fc-scroller,
.fc-scroller-liquid-absolute {
    /* overflow: hidden !important; */
}

.fc-daygrid-day {
    z-index: 1;
}

/*
 *  CUSTOM SCROLL BAR 
 */

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
    background-color: #000000;
}

.primaryNavBarWithLogo {
    z-index: 2;
}

.card-title {
    margin-bottom: 0px;
}

/* Random calendar*/
.fc-theme-standard td,
.fc-theme-standard th {}

/*Position the modals on the screen*/
.modalRight .modal-dialog {
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    ;
}

.modalRight .modal-content {

    height: 100vh;
    border-radius: 0px;
}


.modalLeft .modal-dialog {
    margin-left: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    ;
}

.modalLeft .modal-content {

    height: 100vh;
    border-radius: 0px;
}

.omniBar {
    height: 100vh;
    width: 60px;
    background-color: #3c3c3c;
    position: fixed;
    text-align: center;

    padding-top: 20px;
}

.sidebar {
    /* margin-left:60px; */
    background: #fff;
    z-index: 101;

}

.navItemSmaller span {
    font-size: 0.9em;
}

.navItemSmaller a {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    margin: 0px;
}

.bg-secondary,
.btn-secondary {
    background: #C12064 !important;
    color: #fff !important;

}

.btn-secondary {
    border: solid 1px #C12064;
}

.sidebar .nav-item .nav-link,
.sidebar-icon {
    border: 0px;
    color: #262b40
}

.sidebar .nav-link .sidebar-icon {
    color: #262b40;
}

.sidebar .active .nav-link,
.sidebar .active .sidebar-icon {
    color: #fff !important
}

.sidebar .nav-link:hover {
    color: #fff;
}

.fc-col-header-cell {
    background-color: #3c3c3c;
    border: solid 1px #3c3c3c !important;
}

.fc-col-header-cell a {
    color: #fff;
}

.nav-link:hover .sidebar-icon,
.nav-link:hover .sidebar-text {
    color: #fff;
}


.numberContainer {
    position: absolute;
    top: 0px;
    left: 10px;
}

.card-img,
.card-img-top {
    object-fit: cover;
    height: 200px;
}

#react-confirm-alert {
    z-index: 15000;
    position: fixed;
}

.contentContainer {
    z-index: 99
}

.mainMenu {
    background: rgb(255, 255, 250);
}

.btn-file {
    position: relative;
    overflow: hidden;
    padding: 5px 15px;
    font-size: 14px !important;
    text-transform: capitalize;
}

.btn-file input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    cursor: inherit;
    display: block;
}

.fc .fc-daygrid-day.fc-day-today {
    background: rgb(231, 230, 230) !important;
}

.showVisibility .canBeHidden {
    display: none;
}

.showVisibility:hover .canBeHidden {
    display: inline;
    cursor: pointer;
    color: white;
}

.showVisibility:hover .Invert {

    color: black;
}

.react-datepicker__time-list {
    overflow-x: hidden;
}

.calendarContainer {
    height: calc(100vh - 220px) !important;
    overflow-y: auto;
    overflow-x: hidden;
}

.calendarContainer.full {
    height: calc(100vh - 90px) !important;

}

.vertical-timeline-element-date {
    color: rgba(0, 0, 0, 0.9) !important;
}

.vertical-timeline-element-icon {
    text-align: center;
    vertical-align: middle;
}

.postListcontainer {
    overflow: hidden;
    overflow-y: auto;
}

.activityListContainer {
    height: calc(100vh - 350px);
    overflow-y: auto;
    overflow-x: hidden;
}

.borderLeft {
    border-left: 1px solid #c0c0c0;
}

.accordion-flush .accordion-button:not(.collapsed) {
    color: #000;
    font-weight: bold;
}



.modalRight .header {
    border-bottom: 0.0625rem solid #eaedf2;
    display: block;
}

/* CHAT */
.onlineUsers {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.onlineUsers li {
    float: left;
    padding: 0;
    margin: 0;
    width: 32px;
}

.chatSidebar {
    position: fixed;
    right: 0px;
    bottom: 0px;
    height: 80vh;
    z-index: 101;
    background: white;
}

.chatSidebar.conversation {
    max-height: 50% !important;
    bottom: 0px;

}

.chatSidebar.conversation .messages {
    height: 70%;
    overflow: hidden;
    overflow-y: auto;
}

.chatSidebar.conversation .messageContainer {
    height: 30%;
    padding-bottom: 5%;
    padding-top: 1%;
}

.chatSidebar .conversationContainer {
    position: relative;
    height: 100%
}

nav .btn .icon-badge {
    top: 3px !important;
}

.avatarContainer {
    position: relative;
}

.status {
    font-size: 9px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: 3;

}

.quarters h6 {
    position: absolute;
    z-index: 10;
}

.userListContainer,
.conversationContainer {
    height: calc(100vh - 130px)
}

.userListContainer {
    border-right: solid 1px #c0c0c0;

}

.userListContainer .clickable,
.conversationHeader {
    border-bottom: solid 1px #c0c0c0;
}

.conversationContainer .messages {
    height: 80%;
    overflow: auto;
}

.conversationContainer .messageContainer {
    bottom: 0px;
}

.conversation.active {
    background-color: #C12064;
    border-left: 1px solid #C12064;
    color: #fff !important;
}

.contactContainer {
    height: 80vh;
}


/* NOTES */
.title-text {
    border: 0px;
    font-size: 24px;
    color: #000;
}

.notesMenu li {

    padding: 5px 10px;
    list-style-type: none;
    margin-bottom: 10px;

}

.notesMenu li:hover {
    background-color: white;
    cursor: pointer;
}

.notesMenu li.active {
    background-color: white;
    box-shadow: #c0c0c0;
}

.clickable {
    border-radius: 10px;
}

.modal label {
    margin-bottom: 0px;
    margin-top: 5px;
}


/* SYNCFUSION */
@import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-grids/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-react-spreadsheet/styles/material.css';

/* PDF VIEWER */
@import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import "../../node_modules/@syncfusion/ej2-pdfviewer/styles/material.css";
/*  WORD VIEWER */

@import "../../node_modules/@syncfusion/ej2-react-documenteditor/styles/material.css";

@import "../../node_modules/@syncfusion/ej2-react-documenteditor/styles/material.css";


.timeline .vertical-timeline-element-content .btn-group {
    display: none;
    position: absolute;
    right: 0px;
    top: 0px;

}

.timeline .vertical-timeline-element-content:hover .btn-group {
    display: block
}


.modalRight .modal-header {
    height: 5vh;
}

.modalRight .modal-footer {
    height: 10vh;
}

.modal-body {
    background: #f6f6f6;
}

.modalRight .modal-body {
    height: 85vh;
    overflow: auto;
}

.modalRight .modal-content {
    height: 100vh;
    border-radius: 0px;
}



.avatar {
    width: 26px;
    height: 26px;
    background: #ccc;
    color: #000;
    border-radius: 13px;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
}

.avatar i {
    margin-top: 3px;
    display: block;
}